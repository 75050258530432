// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aashayein-jsx": () => import("./../../../src/pages/aashayein.jsx" /* webpackChunkName: "component---src-pages-aashayein-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-aboutchairman-jsx": () => import("./../../../src/pages/aboutchairman.jsx" /* webpackChunkName: "component---src-pages-aboutchairman-jsx" */),
  "component---src-pages-aboutdirector-jsx": () => import("./../../../src/pages/aboutdirector.jsx" /* webpackChunkName: "component---src-pages-aboutdirector-jsx" */),
  "component---src-pages-aboutori-jsx": () => import("./../../../src/pages/aboutori.jsx" /* webpackChunkName: "component---src-pages-aboutori-jsx" */),
  "component---src-pages-aboutprincipal-jsx": () => import("./../../../src/pages/aboutprincipal.jsx" /* webpackChunkName: "component---src-pages-aboutprincipal-jsx" */),
  "component---src-pages-acandmyinfra-jsx": () => import("./../../../src/pages/acandmyinfra.jsx" /* webpackChunkName: "component---src-pages-acandmyinfra-jsx" */),
  "component---src-pages-admission-jsx": () => import("./../../../src/pages/admission.jsx" /* webpackChunkName: "component---src-pages-admission-jsx" */),
  "component---src-pages-allblog-jsx": () => import("./../../../src/pages/allblog.jsx" /* webpackChunkName: "component---src-pages-allblog-jsx" */),
  "component---src-pages-amd-jsx": () => import("./../../../src/pages/amd.jsx" /* webpackChunkName: "component---src-pages-amd-jsx" */),
  "component---src-pages-become-teacher-jsx": () => import("./../../../src/pages/become-teacher.jsx" /* webpackChunkName: "component---src-pages-become-teacher-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-calenderview-jsx": () => import("./../../../src/pages/calenderview.jsx" /* webpackChunkName: "component---src-pages-calenderview-jsx" */),
  "component---src-pages-campusinfra-jsx": () => import("./../../../src/pages/campusinfra.jsx" /* webpackChunkName: "component---src-pages-campusinfra-jsx" */),
  "component---src-pages-canteen-menu-jsx": () => import("./../../../src/pages/canteenMenu.jsx" /* webpackChunkName: "component---src-pages-canteen-menu-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-careersatsas-jsx": () => import("./../../../src/pages/careersatsas.jsx" /* webpackChunkName: "component---src-pages-careersatsas-jsx" */),
  "component---src-pages-cca-jsx": () => import("./../../../src/pages/cca.jsx" /* webpackChunkName: "component---src-pages-cca-jsx" */),
  "component---src-pages-celebrations-jsx": () => import("./../../../src/pages/celebrations.jsx" /* webpackChunkName: "component---src-pages-celebrations-jsx" */),
  "component---src-pages-circular-jsx": () => import("./../../../src/pages/circular.jsx" /* webpackChunkName: "component---src-pages-circular-jsx" */),
  "component---src-pages-cocurricular-1-jsx": () => import("./../../../src/pages/cocurricular1.jsx" /* webpackChunkName: "component---src-pages-cocurricular-1-jsx" */),
  "component---src-pages-cocurricular-jsx": () => import("./../../../src/pages/cocurricular.jsx" /* webpackChunkName: "component---src-pages-cocurricular-jsx" */),
  "component---src-pages-competitions-jsx": () => import("./../../../src/pages/competitions.jsx" /* webpackChunkName: "component---src-pages-competitions-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-course-details-jsx": () => import("./../../../src/pages/course-details.jsx" /* webpackChunkName: "component---src-pages-course-details-jsx" */),
  "component---src-pages-courses-jsx": () => import("./../../../src/pages/courses.jsx" /* webpackChunkName: "component---src-pages-courses-jsx" */),
  "component---src-pages-createartical-js": () => import("./../../../src/pages/createartical.js" /* webpackChunkName: "component---src-pages-createartical-js" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-facility-jsx": () => import("./../../../src/pages/facility.jsx" /* webpackChunkName: "component---src-pages-facility-jsx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-jsx": () => import("./../../../src/pages/infrastructure.jsx" /* webpackChunkName: "component---src-pages-infrastructure-jsx" */),
  "component---src-pages-majareventlist-jsx": () => import("./../../../src/pages/majareventlist.jsx" /* webpackChunkName: "component---src-pages-majareventlist-jsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-news-details-js": () => import("./../../../src/pages/news-details.js" /* webpackChunkName: "component---src-pages-news-details-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-photo-jsx": () => import("./../../../src/pages/photo.jsx" /* webpackChunkName: "component---src-pages-photo-jsx" */),
  "component---src-pages-prabhat-jsx": () => import("./../../../src/pages/prabhat.jsx" /* webpackChunkName: "component---src-pages-prabhat-jsx" */),
  "component---src-pages-prayas-jsx": () => import("./../../../src/pages/prayas.jsx" /* webpackChunkName: "component---src-pages-prayas-jsx" */),
  "component---src-pages-preprimary-jsx": () => import("./../../../src/pages/preprimary.jsx" /* webpackChunkName: "component---src-pages-preprimary-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-primary-jsx": () => import("./../../../src/pages/primary.jsx" /* webpackChunkName: "component---src-pages-primary-jsx" */),
  "component---src-pages-samarpan-jsx": () => import("./../../../src/pages/samarpan.jsx" /* webpackChunkName: "component---src-pages-samarpan-jsx" */),
  "component---src-pages-secondary-jsx": () => import("./../../../src/pages/secondary.jsx" /* webpackChunkName: "component---src-pages-secondary-jsx" */),
  "component---src-pages-snrsecondary-jsx": () => import("./../../../src/pages/snrsecondary.jsx" /* webpackChunkName: "component---src-pages-snrsecondary-jsx" */),
  "component---src-pages-sport-jsx": () => import("./../../../src/pages/sport.jsx" /* webpackChunkName: "component---src-pages-sport-jsx" */),
  "component---src-pages-sportinfra-jsx": () => import("./../../../src/pages/sportinfra.jsx" /* webpackChunkName: "component---src-pages-sportinfra-jsx" */),
  "component---src-pages-teacher-details-js": () => import("./../../../src/pages/teacher-details.js" /* webpackChunkName: "component---src-pages-teacher-details-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-teamsasadmin-jsx": () => import("./../../../src/pages/teamsasadmin.jsx" /* webpackChunkName: "component---src-pages-teamsasadmin-jsx" */),
  "component---src-pages-teamsasedu-jsx": () => import("./../../../src/pages/teamsasedu.jsx" /* webpackChunkName: "component---src-pages-teamsasedu-jsx" */),
  "component---src-pages-transfercertificate-jsx": () => import("./../../../src/pages/transfercertificate.jsx" /* webpackChunkName: "component---src-pages-transfercertificate-jsx" */),
  "component---src-pages-youtubevideo-jsx": () => import("./../../../src/pages/youtubevideo.jsx" /* webpackChunkName: "component---src-pages-youtubevideo-jsx" */)
}

